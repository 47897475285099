<template>
  <div v-if="!manualTopicId" class="d-flex align-items-center">
    <span class="mr-2 font-weight-bold">{{totalRecords}}</span>
    <span class="text-muted">Posts Found</span>
    <p-paginator :totalRecords="totalRecords"
                :rows="perPage"
                :first="currentPageFirstIndex"
                @page="updatePage($event)" />
    <span class="ml-5 mr-3">Sort By</span>
    <mad-lib-select v-model="sortBy" :options="sortOptions" :formatter="sortOptionFormatter"></mad-lib-select>
  </div>
</template>

<script>
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
export default {
  name: 'ForumPaging',
  components: { MadLibSelect },
  data () {
    return {
      sortOptions: [
        'createdOn desc',
        'createdOn',
        'upVotes desc',
        'requiresFluencyAssistance desc',
        'replyCount desc',
        'replyCount'
      ]
    }
  },
  computed: {
    cacheHeaders () {
      return this.$store.getters['forum/cacheHeaders']
    },
    totalRecords () {
      return parseInt(this.cacheHeaders?.totalrecords || 0)
    },
    perPage () {
      return this.cacheHeaders.pageSize
    },
    currentPage () {
      return this.cacheHeaders.page
    },
    currentPageFirstIndex () {
      return (this.currentPage - 1) * this.perPage
    },
    manualTopicId () {
      return this.$store.getters['forum/manualTopicId']
    },
    sortBy: {
      get () {
        return this.cacheHeaders.sortBy
      },
      set (val) {
        this.$store.dispatch('forum/changeSort', val)
      }
    }
  },
  methods: {
    updatePage (paginatorPageObj) {
      const requestedPage = (paginatorPageObj.page + 1)
      this.$store.dispatch('forum/pageSearchResults', requestedPage)
    },
    sortOptionFormatter (text) {
      switch (text) {
        case 'createdOn desc':
          return 'Newest First'
        case 'createdOn':
          return 'Oldest First'
        case 'upVotes desc':
          return 'Votes'
        case 'requiresFluencyAssistance desc':
          return 'Requires Fluency Assistance'
        case 'replyCount desc':
          return 'Most Replies First'
        case 'replyCount':
          return 'Fewest Replies First'
        default:
          return text
      }
    }
  }
}
</script>
