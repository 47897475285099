<template>
  <div>

    <fluency-loader v-if="loading" class="py-5 my-5"></fluency-loader>

<!--    success-->

    <t-alert v-else-if="newlyCreatedTopic" show variant="success">
      <h5 class="alert-heading">Thanks For The Input!</h5>
      <p>
        <span>Your post will now show in the user forum. If necessary, a Fluency team member will get back to you.</span>
      </p>
      <nuxt-link :href="sharableLink" :target="$route.name ==='insights-feedback' ? '_self' : '_blank'">Go To My Post</nuxt-link>
      <t-button-inline class="ml-5" @click="$store.commit('forum/authoring', false)">Close</t-button-inline>
    </t-alert>

    <template v-else>
      <h5 v-if="showHeading">Create a Post</h5>

<!--      channel-->

      <div class="d-flex align-items-center flex wrap">
        <span class="mr-3 py-3">What channel should this be in?*</span>
        <basic-mad-lib-input v-if="ultraUser" v-model="channelName" :type-ahead-options="channelOptions" placeholder="Select or Create New"></basic-mad-lib-input>
        <mad-lib-select v-else v-model="channelName" :options="channelOptions"></mad-lib-select>
      </div>
      <b-form-group v-if="isNewChannel" label="Since this is a new channel, please give it a description">
        <b-form-input v-model="newChannelDescription"></b-form-input>
      </b-form-group>

      <div class="d-flex mt-3 mb-4">
        <div class="w-50">

          <!--      fluency assistance-->

          <div class="d-flex align-items-center">
            <b-form-checkbox switch :checked="requiresFluencyAssistance" @input="requiresFluencyAssistance = $event"></b-form-checkbox>
            <span>Require Fluency Assistance</span>
          </div>

          <!--      screenshot-->

          <div v-if="$route.name !=='insights-feedback'" class="d-flex align-items-center pt-3">
            <b-form-checkbox switch :checked="includeScreenshot" @input="includeScreenshot"></b-form-checkbox>
            <span>Include Screenshot</span>
          </div>

        </div>
        <div class="w-50">

          <!--      impacting delivery -->

          <div v-if="channelName === 'Troubleshooting'" class="d-flex align-items-center">
            <b-form-checkbox switch :checked="impactingDelivery" @input="impactingDelivery = $event"></b-form-checkbox>
            <span>Impacting Delivery</span>
          </div>

          <!--          severity-->

          <div v-if="channelName === 'Troubleshooting'" class="d-flex align-items-center">
            <span class="mr-3">Severity Level</span>
            <mad-lib-select v-model="severity" :options="['Question', 'Irritation', 'Concern', 'Critical']" boundary="scrollParent"></mad-lib-select>
          </div>

        </div>
      </div>

<!--      title-->

      <b-form-group label="Title*">
        <b-form-input v-model="title"></b-form-input>
      </b-form-group>

<!--      message-->

      <forum-content-input v-if="renderMessageInput"
                           :message="message" @update:message="message = $event" />

<!--      tags-->

      <div class="d-flex align-items-center flex wrap">
        <span class="mr-3 py-3">Set tags for your post</span>
        <mad-lib-input v-model="tags" disable-auto-focus badge-variant="light" badge-classes="border p-2"></mad-lib-input>
      </div>

<!--      backpack link-->

      <div v-if="!editingTopic && channelName === 'Troubleshooting'" class="d-flex align-items-center flex-wrap">
        <span class="mr-3 py-3">Link</span>
        <basic-mad-lib-input v-model="backpackLink"
                             fill-width
                             :custom-text-formatter="truncateBeginning"
                             class="flex-grow-1" />
      </div>

<!--      submit-->

      <div v-if="showBtn" class="d-flex justify-content-end">
        <b-button variant="primary" :disabled="!valid" @click="submitTopic()">Submit</b-button>
      </div>

<!--      errors-->

      <t-alert :show="invalidAttempt" variant="info" class="mt-3 mb-0">
        Please make sure your post has a channel, title, and message before submitting.
      </t-alert>
      <t-alert :show="error" variant="warning">
        <h5 class="alert-heading">Oh No! Something Went Wrong</h5>
        <p class="mb-0">There was a problem submitting your post. Please try again later.</p>
      </t-alert>
    </template>
  </div>
</template>

<script>
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import MadLibInput from 'core-ui/components/inputs/madLibInput'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import ForumContentInput from '@/components/common/forum/forumContentInput'
import html2canvas from 'html2canvas'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
export default {
  name: 'ForumCreateTopic',
  components: { FluencyLoader, ForumContentInput, MadLibSelect, MadLibInput, BasicMadLibInput },
  props: {
    btnValid: {
      type: Boolean
      // for 2 way data binding
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    showHeading: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      title: '',
      message: '',
      channelName: '',
      newChannelDescription: '',
      requiresFluencyAssistance: false,
      tags: [],
      invalidAttempt: false,
      error: false,
      newlyCreatedTopic: false,
      ultraUser: false, // just controls creating a new channel. hopefully we open this up sometime
      renderMessageInput: false,
      includeScreenshot: false,
      impactingDelivery: false,
      severity: 'Question',
      backpackLink: this.$route.name === 'insights-feedback' ? '' : `${window.location.origin}${this.$route.fullPath}`,
      loading: false
    }
  },
  watch: {
    valid (val) {
      this.$emit('update:btnValid', val)
    }
  },
  computed: {
    channels () {
      return this.$store.getters['forum/channels']
    },
    channelOptions () {
      return this.channels.map(ch => ch.name)
    },
    isNewChannel () {
      if (!this.channelName) return false
      return !this.channelOptions.includes(this.channelName)
    },
    valid () {
      return !!(
        this.title &&
        this.message &&
        this.channelName
      )
    },
    sharableLink () {
      if (!this.newlyCreatedTopic) return ''
      let path = `${window.location.origin}/insights/feedback/?id=${this.newlyCreatedTopic.forumTopicId}`
      if (this.$route.query.fsEnv) {
        path = `${path}&fsEnv=${this.$route.query.fsEnv}`
      }
      return path
    },
    editingTopic () {
      return this.$store.getters['forum/editingTopic']
    }
  },
  methods: {
    async submitTopic () {
      if (this.valid) {
        this.loading = true
        if (!this.editingTopic && this.channelName === 'Troubleshooting') {
          this.message = `${this.message}\n\n[Link To Issue](${this.backpackLink})`
        }
        if (this.includeScreenshot) {
          await this.takeScreenshot()
        }
        const newTopic = await this.$store.dispatch('forum/submitTopic', {
          topic: {
            ...(this.editingTopic || {}),
            ...(this.editingTopic?.channel === this.channelName ? {} : { forumChannelId: undefined }), // wipe out channel id if user has changed channel name
            title: this.title,
            message: this.message,
            tagsJson: this.tags,
            requiresFluencyAssistance: this.requiresFluencyAssistance,
            ...(this.channelName === 'Troubleshooting' ? { impactingDelivery: this.impactingDelivery, severity: this.severity } : {})
          },
          channelName: this.channelName,
          description: this.newChannelDescription
        })
        if (newTopic) {
          this.newlyCreatedTopic = newTopic
          this.title = ''
          this.message = ''
          this.channelName = ''
          this.newChannelDescription = ''
          this.tags = []
          this.invalidAttempt = false
          this.requiresFluencyAssistance = false
          this.impactingDelivery = false
          this.severity = ''
        } else {
          this.error = true
        }
        this.loading = false
      } else {
        this.invalidAttempt = true
      }
    },
    truncateBeginning (text) {
      return this.$filters.truncate(text, 60, true)
    },
    async takeScreenshot () {
      const canvas = await html2canvas(document.body)
      const blob = await new Promise(resolve => canvas.toBlob(resolve))
      const formData = new FormData()
      formData.append('file', blob, `screenshot_${Date.now()}.png`)
      const response = await this.$res.set.imageUpload(formData, undefined, { useShare: true })
      if (response) {
        this.message = `${this.message}\n\n![](${response.location})`
      }
    }
  },
  mounted () {
    if (this.editingTopic) {
      const { title, message, channel: channelName, tagsJson: tags, requiresFluencyAssistance, severity, impactingDelivery } = this.editingTopic
      this.title = title
      this.message = message
      this.channelName = channelName
      this.tags = tags
      this.requiresFluencyAssistance = requiresFluencyAssistance
      this.severity = severity || ''
      this.impactingDelivery = impactingDelivery || false
    }
    this.renderMessageInput = true
  }
}
</script>
