<template>
  <div>

<!--    topic id filter-->

    <div v-if="manualTopicId" class="d-flex mb-4">
      <h3>
        <b-badge class="d-flex align-items-center">
          <span>Post Id: {{manualTopicId}}</span>
          <span @click="search()"><fluency-icon type="close" class="ml-3 cursor-pointer" /></span>
        </b-badge>
      </h3>
    </div>

<!--    free text search-->

    <h6 class="mb-4">Search Existing Posts</h6>
    <content-filter v-model="searchPhrase"
                    placeholder="Search By Keyword Or Phrase..."
                    :debounce="500"
                    prependIcon="search"
                    @keyupEnter="search()"></content-filter>
    <div class="mt-5" :class="{'row': !stacked}">
      <div :class="{'col-6': !stacked}">

<!--        select channel-->

        <forum-channel :search-on-change="searchOnChannelChange"></forum-channel>

      </div>
      <div :class="{'col-6': !stacked}">

<!--        tags-->

        <h6 class="mb-3">Search For Specific Tags</h6>
        <mad-lib-input v-model="searchTags" disable-auto-focus badge-variant="light" badge-classes="border p-2"></mad-lib-input>

      </div>
    </div>

    <div v-if="showSearchBtn" class="d-flex mt-5">
      <b-button variant="primary" @click="search()">Search</b-button>
    </div>
  </div>
</template>

<script>
import ForumChannel from '@/components/common/forum/forumChannel'
import ContentFilter from '@/components/common/forms/content-filter'
import MadLibInput from 'core-ui/components/inputs/madLibInput'
export default {
  name: 'ForumSearch',
  components: { MadLibInput, ContentFilter, ForumChannel },
  props: {
    stacked: {
      type: Boolean,
      default: false
    },
    showSearchBtn: {
      type: Boolean,
      default: false
    },
    searchOnChannelChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    searchPhrase: {
      get () {
        return this.$store.getters['forum/searchPhrase']
      },
      set (val) {
        if (val !== this.searchPhrase) {
          this.$store.commit('forum/searchPhrase', val)
        }
      }
    },
    searchTags: {
      get () {
        return this.$store.getters['forum/searchTags']
      },
      set (val) {
        this.$store.commit('forum/searchTags', val)
      }
    },
    manualTopicId () {
      return this.$store.getters['forum/manualTopicId']
    }
  },
  methods: {
    async search () {
      if (this.manualTopicId) {
        await this.$store.commit('forum/manualTopicId', null)
      }
      await this.$store.dispatch('forum/search')
    }
  }
}
</script>
