<template>
  <div class="d-flex flex-column align-items-center">
    <b-button variant="ghost-info" class="d-flex align-items-center p-3" @click="vote(true)">
      <fluency-icon type="caretUp"></fluency-icon>
    </b-button>
    <h5 class="my-3">{{voteCount}}</h5>
    <b-button variant="ghost-info" class="d-flex align-items-center p-3" @click="vote(false)">
      <fluency-icon type="caretDown"></fluency-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ForumVotes',
  props: {
    item: {
      type: Object, // topic or reply
      required: true
    }
  },
  computed: {
    type () {
      return this.item.forumTopicReplyId ? 'reply' : 'topic'
    },
    voteCount () {
      return (this.item.upVotes || 0) - (this.item.downVotes || 0)
    }
  },
  methods: {
    async vote (up) {
      if (this.type === 'reply') {
        await this.$store.dispatch('forum/replyVote', { forumTopicReplyId: this.item.forumTopicReplyId, up })
      } else {
        await this.$store.dispatch('forum/topicVote', { forumTopicId: this.item.forumTopicId, up })
      }
    }
  }
}
</script>
