<template>
  <b-form-group label="Message*">
    <markdown-input :value="message" @input="updateMessage($event)"></markdown-input>
  </b-form-group>
</template>

<script>
import MarkdownInput from '@/components/common/markdownInput'
export default {
  name: 'ForumContentInput',
  components: { MarkdownInput },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateMessage (value) {
      this.$emit('update:message', value)
    }
  }
}
</script>
