<template>
  <b-form-group>
    <template #label>
    <h6>
      Select A Feedback Channel...
    </h6>
    </template>
    <b-form-radio-group
      :checked="selectedChannelId"
      @input="selectedChannelId = $event"
      :options="channelOptions"
      name="channel options"
      stacked
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'forumChannel',
  props: {
    searchOnChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    channels () {
      return this.$store.getters['forum/channels']
    },
    channelOptions () {
      return [
        { value: -1, html: '<div>All Channels</div><div class="small text-muted">Search across all channels.</div>' },
        ...this.channels.map(ch => ({
          value: ch.forumChannelId,
          html: `<div>${ch.name}</div><div class="small text-muted">${ch.description}</div>`
        }))
      ]
    },
    selectedChannelId: {
      get () {
        return this.$store.getters['forum/selectedChannelId']
      },
      async set (val) {
        if (val !== this.selectedChannelId) {
          // this.$store.dispatch('forum/setChannel', val)
          await this.$store.commit('forum/selectedChannelId', val)
          if (this.searchOnChange) {
            await this.$store.dispatch('forum/search')
          }
        }
      }
    }
  }
}
</script>
