<template>
  <b-dropdown variant="link" toggle-class="text-info forum-topic-options-toggle" no-caret boundary="viewport" right>
    <template #button-content>
      <fluency-icon type="ellipses"></fluency-icon>
    </template>
    <template v-for="item in dropdownItems" :key="item">

<!--      copy-->

      <b-dropdown-item v-if="item === 'copy'" :class="dropdownClasses" v-click-copy="sharableLink">
        <fluency-icon type="copy" :class="iconClass"></fluency-icon>
        <span>Copy Shareable Link</span>
      </b-dropdown-item>

<!--      link-->

      <b-dropdown-item v-if="item === 'link'" :class="dropdownClasses" :href="sharableLink" target="_blank" @click.stop>
        <fluency-icon type="linkOut" :class="iconClass"></fluency-icon>
        <span>Open In A New Tab</span>
      </b-dropdown-item>

<!--      un-request Fluency-->

      <b-dropdown-item v-if="item === 'unrequestFluency'" :class="dropdownClasses" @click.stop="requireFluency(false)">
        <fluency-icon type="cancel" :class="iconClass"></fluency-icon>
        <span>Remove Request For Fluency Assistance</span>
      </b-dropdown-item>

<!--      request Fluency-->

      <b-dropdown-item v-if="item === 'requestFluency'" :class="dropdownClasses" @click.stop="requireFluency(true)">
        <fluency-icon type="warning" :class="iconClass"></fluency-icon>
        <span>Request Fluency Assistance</span>
      </b-dropdown-item>

<!--      un-request Dev-->

      <b-dropdown-item v-if="item === 'unrequestDev'" :class="dropdownClasses" @click.stop="requireDev(false)">
        <fluency-icon type="cancel" :class="iconClass"></fluency-icon>
        <span>Remove Request For Dev Assistance</span>
      </b-dropdown-item>

<!--      request Dev-->

      <b-dropdown-item v-if="item === 'requestDev'"  :class="dropdownClasses" @click.stop="requireDev(true)">
        <fluency-icon type="bug" :class="iconClass"></fluency-icon>
        <span>Request Dev Assistance</span>
      </b-dropdown-item>

<!--      subscribe-->

      <b-dropdown-item v-if="item === 'subscribe'" :class="dropdownClasses" @click.stop="subscribe(false)">
        <fluency-icon type="envelopeClosed" :class="iconClass"></fluency-icon>
        <span>Subscribe To This Topic</span>
      </b-dropdown-item>

<!--      claim-->

      <b-dropdown-item v-if="item === 'claim'" :class="dropdownClasses" @click.stop="subscribe(true)">
        <fluency-icon type="star" :class="iconClass"></fluency-icon>
        <span>Assign This Topic To Yourself</span>
      </b-dropdown-item>

<!--      release claim-->

      <b-dropdown-item v-if="item === 'releaseClaim'" :class="dropdownClasses" @click.stop="subscribe(false)">
        <fluency-icon type="cancel" :class="iconClass"></fluency-icon>
        <span>Release Assignment</span>
      </b-dropdown-item>

<!--      edit-->

      <b-dropdown-item v-if="item === 'edit'" :class="dropdownClasses" @click.stop="$store.commit('forum/editingTopic', topic)">
        <fluency-icon type="edit" :class="iconClass"></fluency-icon>
        <span>Edit This Topic</span>
      </b-dropdown-item>

<!--      delete-->

      <b-dropdown-item v-if="item === 'delete'" :class="dropdownClasses" @click.stop="deleteTopic()">
        <fluency-icon type="delete" :class="iconClass"></fluency-icon>
        <span>Delete This Topic</span>
      </b-dropdown-item>

    </template>
  </b-dropdown>

</template>

<script>
export default {
  name: 'ForumTopicOptions',
  props: {
    topic: {
      type: Object,
      required: true
    },
    subscribed: {
      type: Boolean,
      default: false
    },
    assignedToCurrentUser: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dropdownClasses: 'd-flex align-items-center',
      iconClass: 'mr-2 text-info'
    }
  },
  computed: {
    sharableLink () {
      let path = `${window.location.origin}/insights/feedback/?id=${this.topic.forumTopicId}`
      if (this.$route.query.fsEnv) {
        path = `${path}&fsEnv=${this.$route.query.fsEnv}`
      }
      return path
    },
    userName () {
      return this.$store.getters.user?.userName
    },
    internalUser () {
      return this.$store.getters?.user?.internalUser
    },
    dropdownItems () {
      const items = ['copy']
      if (this.$route.name !== 'insights-feedback') {
        items.push('link')
      }
      if (this.topic.requiresFluencyAssistance) {
        if (!this.topic.requiresDevAssistance) {
          items.push('unrequestFluency')
        }
        if (this.internalUser) {
          if (this.topic.requiresDevAssistance) {
            items.push('unrequestDev')
          } else {
            items.push('requestDev')
          }
        }
      } else {
        items.push('requestFluency')
      }
      if (!this.subscribed) {
        items.push('subscribe')
      }
      if (this.internalUser && this.assignedToCurrentUser) {
        items.push('releaseClaim')
      } else if (this.internalUser) {
        items.push('claim')
      }
      if (this.internalUser || this.userName === this.topic.userName) {
        items.push('edit')
        items.push('delete')
      }
      return items
    }
  },
  methods: {
    requireFluency (val) {
      this.$store.dispatch('forum/requireFluency', {
        forumTopicId: this.topic.forumTopicId,
        value: val
      })
    },
    requireDev (val) {
      this.$store.dispatch('forum/requireFluencyDev', {
        forumTopicId: this.topic.forumTopicId,
        value: val
      })
    },
    subscribe (claimed) {
      this.$store.dispatch('forum/subscribe', {
        topic: this.topic,
        claimed
      })
    },
    async deleteTopic () {
      const cfm = await this.$confirm('This will permanently remove this topic.')
      if (cfm) {
        this.$store.dispatch('forum/deleteTopic', this.topic)
      }
    }
  }
}
</script>
